@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-serif;
    @apply font-normal;
  }

  *:focus {
    @apply outline-primary-300;
  }
}
